import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import auditEventsApiSlice from 'reducer/auditEventsApiSlice';
import reprocessOrdersApiSlice from 'reducer/reprocessOrdersApiSlice';
import appSlice from 'reducer/appSlice';
import dashboardSlice from 'reducer/dashboardSlice';
import OktaSlice from 'reducer/oktaSlice';

const reducers = combineReducers({
  reprocessReducer : reprocessOrdersApiSlice,
  appSliceReducer: appSlice,
  auditEventsApiSliceReducer: auditEventsApiSlice,
  dashboardSliceReducer : dashboardSlice,
  oktaSliceReducer: OktaSlice
});



const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV !== 'PROD',
  middleware: [thunk],
});

export const persistor = persistStore(store);
