import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { calculateCountByScenario } from "../utils/responseProcessorUtil";
import { postSyncCall, getOrderDetailCall } from "../utils/apiAdapter";
import { getSyncCall, getAsyncCall } from "../utils/apiAdapter";
import { getOktaAuthToken } from "routes/routes";
import { saveAs } from "file-saver";
import { apiSource } from "constants/Constants";

const payloadDateFormat = (rawDate) => {
  const date = new Date(rawDate);
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  return `${date.getUTCFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

const initialState = {
  requestData: {},
  responseData: [],
  selectedOption: "Select Search Option",
  orderSummaryDetailsResponse: {},
  dashboardResponseLevel0: {},
  dashboardResponseLevel1: {},
  dashboardResponseLevel2: {},
  chartOrderType: "Select Order Type",
  dashBoardStatsCount: {},
  metricsFilterSearchRequest: {
    count: 10,
    anchor: 0,
  },
  metricsFilterSearchData: {
    count: 10,
    anchor: 0,
  },
  pieStats: {},
  byOrderType: {},
  searchModal: false,
  levelSelected: "0",
  selectedSearchTopicMessage: "",
  selectedKey: "",
  orderTypeData: {},
  opsAccessToken: "",
  error: "",
  loading: false,
  orderSummaryloader: false,
  globalRegion: 'GC',
  isPayloadModalVisible: false,
  failedStatusOnly: false,  // Level 2 - Display only failed order headers / line items
};

export const fetchPayload = createAsyncThunk(
  "dashboardSlice/fetchPayload",
  (input, {getState}) => {

    const api = `${apiSource}/ops-dashboard/download`;

    const rootState = getState();

    const {startTime, anchor, count, level, reportType} = rootState.dashboardSliceReducer.metricsFilterSearchRequest;
    const requestParams = `orderId=${input['orderId']}&startTime=${startTime}&anchor=${anchor}&count=${count}&level=${level}&reportType=${reportType}`;

    const token = "Bearer " + getOktaAuthToken();

    const headers = {
      'Content-Type': 'application/json',
      geoDetect: rootState.dashboardSliceReducer.globalRegion,
      orderType: input["orderType"],
      downloadType: input["downloadType"],
      bearertoken: token,
    };

    const res = getSyncCall(api, headers, requestParams);
    const idKeyOneForS4 = "xNIKExUniqueidentifier";
    const idKeyTwoForS4 = "xNIKExUNIQUEIDENTIFIER";
    res.then(
      (response) => {
        const orderIdFromPayload =
          input["downloadType"] !== "s4"
            ? response["id"]
            : response[idKeyOneForS4] !== undefined
            ? response[idKeyOneForS4]
            : response[idKeyTwoForS4];
        const payload = JSON.stringify(response);
        var blob = new Blob([payload], { type: "application/json" });
        const fileNameInitials = input["downloadType"];
        const saveFile = () => {
          saveAs(blob, orderIdFromPayload + "__" + fileNameInitials + ".json");
        };
        saveFile();
      },
      (failure) => {
        console.log(failure);
      }
    );
  }
);

const fetchOrdersSummary = createAsyncThunk(
  "dashboardSlice/fetchOrdersSummary",
  async (input) => {
    return getAsyncCall(input.api, input.header, input.data);
  })

const fetchOrderHeader = createAsyncThunk(
  "dashboardSlice/fetchOrderHeader",
  async (params) => {
    const api = `${apiSource}/osd-summary/orderheader/details/${params.reportType}`;
    return getAsyncCall(api, null, params);
  })

const fetchOrderDetails = createAsyncThunk(
  "dashboardSlice/fetchOrderDetails",
  async (params) => {
    const api = `${apiSource}/ops-dashboard/call/details`;
    return getOrderDetailCall(api, null, params);
  })

export const fetchForSearchCriteriaOFOA = createAsyncThunk(
  "dashboardSlice/fetchForSearchCriteriaOFOA",
  async (_, { getState, dispatch }) => {

    const root = getState();
    const metricsFilterSearchRequest =
      root.dashboardSliceReducer.metricsFilterSearchRequest;
    const level = metricsFilterSearchRequest.level;

    if (level === "0") {
      const api = `${apiSource}/ops-dashboard/summary/stats`;

      const header = {
        'x-nike-appid': 'ceshipment',
        'content-type': 'application/json'
      };

      dispatch(fetchOrdersSummary( {api, header, data: metricsFilterSearchRequest} ));
    } else if (level === "1") {
      dispatch(fetchOrderHeader(metricsFilterSearchRequest))
    } else if (level === "2") {
      dispatch(fetchOrderDetails(metricsFilterSearchRequest))
    }
  }
);

export const fetchOrderSummaryData = createAsyncThunk(
  "dashboardSlice/fetchOrderSummaryData",
  async (_, { getState }) => {
    //common method for all the three levels this will be called post metricsFilterSearchRequest creation
    const root = getState();
    const metricsFilterSearchRequest =
      root.dashboardSliceReducer.metricsFilterSearchRequest;

    try {
      let api = `${apiSource}/ops-dashboard/dashboard`;
      const header = {
        'x-nike-appid': 'ceshipment',
        'content-type': 'application/json'
      };

      const receivedRequestData = {
        opsType: "opsdash",
        opsSubType:"received",
        requestParam: metricsFilterSearchRequest
      };
      const splitRequestData = {
        opsType: "opsdash",
        opsSubType: "split",
        requestParam: metricsFilterSearchRequest
      };
      const overAllStatusRequestData = {
        opsType:"opsdash",
        opsSubType:"overallStatus",
        requestParam: metricsFilterSearchRequest
      };

      const responses = await Promise.all([
        postSyncCall(api, receivedRequestData, header),
        postSyncCall(api, splitRequestData, header),
        postSyncCall(api, overAllStatusRequestData, header)]);
      return responses;

    } catch (err) {
      console.log(err);
    }
  }
);

// metrics infor for Level 0
export const getOrderSummaryReport = createAsyncThunk(
  "dashboardSlice/getOrderSummaryReport",
  (data) => {
    try {
      let requestedData = {};
      let api = `${apiSource}/ops-dashboard/summary/v1/`;
      const response = getSyncCall(api, requestedData, null);
      return calculateCountByScenario(response);
    } catch (e) {
      console.log("getOrderSummaryReport error", e);
    }
  }
);

// -------------------------------------------------------------------------------------
const dashboardslice = createSlice({
  name: "dashboardSlice",
  initialState,
  extraReducers: {
    [fetchPayload.pending]() {},
    [fetchPayload.fulfilled](state, { payload }) {
      state.responseData = payload;
    },
    [fetchPayload.rejected](state) {
      state.error = "Rejected";
    },
    [getOrderSummaryReport.pending]() {},
    [getOrderSummaryReport.fulfilled](state, { payload }) {
      state.responseData = payload;
    },
    [getOrderSummaryReport.rejected](state) {
      state.error = "Rejected";
    },
    [fetchOrdersSummary.pending](state) {
      state.orderSummaryloader = true;
    },
    [fetchOrdersSummary.fulfilled](state, { payload }) {
      state.dashboardResponseLevel0 = payload;
      state.orderSummaryloader = false;
    },
    [fetchOrdersSummary.rejected](state) {
      state.error = "Rejected";
      state.orderSummaryloader = false;
    },

    [fetchOrderHeader.pending](state) {
      state.loading = true;
    },
    [fetchOrderHeader.fulfilled](state, { payload }) {
      state.dashboardResponseLevel1 = payload;
      state.loading = false;
    },
    [fetchOrderHeader.rejected](state) {
      state.error = "Rejected";
      state.loading = false;
    },
    [fetchOrderDetails.pending](state) {
      state.loading = true;
    },
    [fetchOrderDetails.fulfilled](state, { payload }) {
      state.dashboardResponseLevel2 = payload;
      state.loading = false;
    },
    [fetchOrderDetails.rejected](state) {
      state.error = "Rejected";
      state.loading = false;
    },
    [fetchOrderSummaryData.pending](state) {
      state.loading = true;
    },
    [fetchOrderSummaryData.fulfilled](state, { payload }) {

      if (state.levelSelected === "0") {
        state.dashboardResponseLevel0 = {
          received: payload[0].response,
          split: payload[1].response,
          overallStatus: payload[2].response
        };
      }
      state.loading = false;
    },
    [fetchOrderSummaryData.rejected](state) {
      state.error = "Rejected";
      state.loading = false;
    }
  },
  reducers: {
    updateSelectedKeyTopic(state, data) {
      state.selectedSearchTopicMessage = data.payload;
    },
    toggleSearchModal(state) {
      state.searchModal = state.searchModal ? false : true;
    },
    resetSearch(state) {
      state.requestData = {};
    },
    resetState(state) {
      return initialState;
    },
    resetLevelGrid(state) {
      state.orderSummaryDetailsResponse = {};
    },
    resetGrid(state) {
      state.responseData = [];
    },
    setSelectedOption(state, option) {
      state.selectedOption = option.payload;
    },
    resetSelectOption(state) {
      state.selectedOption = "Select Search Option";
    },
    updateLevel(state, level) {
      state.levelSelected = level.payload;
      state.metricsFilterSearchRequest = {
        ...state.metricsFilterSearchRequest,
        level: level.payload,
      };
    },
    updateLevelOnBackClick(state, level) {
      state.levelSelected = level.payload;

      state.metricsFilterSearchRequest = {
        ...state.metricsFilterSearchRequest,
        level: level.payload,
      };

    },
    updatePieStats(state, data) {
      state.pieStats = data.payload;
    },
    updatePageNum(state, data) {
      let metricsFilterSearchRequest = state.metricsFilterSearchRequest;
      const pageData = data.payload;
      if (pageData) {
        return {
          ...metricsFilterSearchRequest,
          count: pageData.count,
          anchor: pageData.anchor,
        };
      }
    },
    //this is when user clicks on a row
    updateRowOrderId(state, data) {
      let metricsFilterSearchRequest = state.metricsFilterSearchRequest;
      if (state.levelSelected === "Level 1") {
        return { ...metricsFilterSearchRequest, rowOrderId: data.payload };
      }
      if (state.levelSelected === "Level 2") {
        return { ...metricsFilterSearchRequest, rowOrderId: data.payload };
      }
    },
    updatePieOrderType(state, data) {
      state.pieOrderType = data.payload;
    },
    updateSelectedKey(state, key) {
      state.selectedKey = key.payload;
    },
    resetResponseLevel1(state) {
      state.dashboardResponseLevel1 = undefined;
    },
    resetResponseLevel2(state) {
      state.dashboardResponseLevel2 = undefined;
    },
    //search filter request
    createSearchRequest(state, data) {
      const level = state.levelSelected;
      const filterSearchRequest = data && data.payload;
      let metricsFilterSearchRequest = { ...state.metricsFilterSearchRequest };

      if (filterSearchRequest) {
        metricsFilterSearchRequest =
          filterSearchRequest.metricsFilterSearchRequest;
        metricsFilterSearchRequest = {
          ...metricsFilterSearchRequest,
          level:
            filterSearchRequest && filterSearchRequest["level"]
              ? filterSearchRequest["level"]
              : level,
        };

        if (filterSearchRequest.startTime) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            startTime: payloadDateFormat(filterSearchRequest["startTime"]),
            // startTime: filterSearchRequest["startTime"],
          };
        }

        if (filterSearchRequest.endTime) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            endTime: payloadDateFormat(filterSearchRequest["endTime"])
          };
        } else if (
          !filterSearchRequest.endDate &&
          metricsFilterSearchRequest.endTime
        ) {
          delete metricsFilterSearchRequest.endTime;
        }
        if (
          filterSearchRequest.orderType !== null &&
          filterSearchRequest.orderType !== "Select Order Type"
        ) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            orderType: filterSearchRequest.orderType,
          };
        } else if (
          filterSearchRequest.orderType &&
          filterSearchRequest.orderType === "Select Order Type" &&
          metricsFilterSearchRequest.orderType
        ) {
          delete metricsFilterSearchRequest.orderType;
        }
        if (
          filterSearchRequest.poType &&
          filterSearchRequest.poType !== "Select PO Type"
        ) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            poType: filterSearchRequest.poType,
          };
        } else if (
          filterSearchRequest.poType &&
          filterSearchRequest.poType === "Select PO Type" &&
          metricsFilterSearchRequest.poType
        ) {
          delete metricsFilterSearchRequest.poType;
        }
        if (
          filterSearchRequest.salesOrg &&
          filterSearchRequest.salesOrg !== "Select Sales Org"
        ) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            salesOrg: filterSearchRequest.salesOrg,
          };
        } else if (
          filterSearchRequest.salesOrg &&
          filterSearchRequest.salesOrg === "Select Sales Org" &&
          metricsFilterSearchRequest.salesOrg
        ) {
          delete metricsFilterSearchRequest.salesOrg;
        }

        if (filterSearchRequest.orderId && filterSearchRequest.level === '2') {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            orderId: filterSearchRequest.orderId,
          };
        } else if (
          !filterSearchRequest.orderId !== undefined &&
          metricsFilterSearchRequest.orderId
        ) {
          delete metricsFilterSearchRequest.orderId;
        }

        if (filterSearchRequest.orderIds) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            orderIds: filterSearchRequest.orderIds,
          };
        } else if (
          !filterSearchRequest.orderIds !== undefined &&
          metricsFilterSearchRequest.orderIds
        ) {
          delete metricsFilterSearchRequest.orderIds;
        }

        if (filterSearchRequest.poNumber) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            poNumber: filterSearchRequest.poNumber,
          };
        } else if (
          !filterSearchRequest.poNumber &&
          metricsFilterSearchRequest.poNumber
        ) {
          delete metricsFilterSearchRequest.poNumber;
        }

        if (filterSearchRequest.poNumbers) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            poNumbers: filterSearchRequest.poNumbers,
          };
        } else if (
          !filterSearchRequest.poNumbers &&
          metricsFilterSearchRequest.poNumbers
        ) {
          delete metricsFilterSearchRequest.poNumbers;
        }

        if (filterSearchRequest.reportType) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            reportType: filterSearchRequest.reportType,
          };
        } else if (filterSearchRequest.reset) {
          if (metricsFilterSearchRequest.reportType) {
            delete metricsFilterSearchRequest.reportType;
          }
        } else if (state.selectedKey) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            reportType: state.selectedKey,
          };
        } else {
          delete metricsFilterSearchRequest.reportType;
        }
        if (filterSearchRequest.anchor !== undefined) {
          metricsFilterSearchRequest.anchor &&
            delete metricsFilterSearchRequest.anchor;
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            anchor: filterSearchRequest.anchor,
          };
        }
        if (filterSearchRequest.count !== undefined) {
          metricsFilterSearchRequest.count &&
            delete metricsFilterSearchRequest.count;
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            count: filterSearchRequest.count,
          };
        } else {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            count: 10,
          };
        }
        metricsFilterSearchRequest = {
          ...metricsFilterSearchRequest,
          backButtonClicked: false,
        };
        if (filterSearchRequest.backButtonClicked) {
          metricsFilterSearchRequest = {
            ...metricsFilterSearchRequest,
            backButtonClicked: true,
          };
        }

        state.metricsFilterSearchData = {
          startTime: filterSearchRequest.startTime,
          endTime: filterSearchRequest.endTime,
        };
      }
      metricsFilterSearchRequest = { ...metricsFilterSearchRequest, region: state.globalRegion !== 'OTHERS' ? state.globalRegion : '' };
      state.metricsFilterSearchRequest = metricsFilterSearchRequest;
    },
    updateOrderTypeData(state, data) {
      state.orderTypeData = data.payload;
    },
    setGlobalRegion(state, {payload}){
      state.globalRegion = payload;
    },
    setPayloadModalVisibility(state, {payload}){
      state.isPayloadModalVisible = payload;
    },
    setFailedStatusOnly(state, {payload}){
      state.failedStatusOnly = payload;
    },
    updateFailedOrdersResponseData(state, requestedData) {

      let orderSummaryData =
        state.dashboardResponseLevel1 &&
        state.dashboardResponseLevel1.data;
      const newReponseData = orderSummaryData.map((order) => {
        if (
          requestedData.payload.orderList &&
          requestedData.payload.orderList.includes(order.orderId)
        ) {
          return {
            ...order,
            comments: requestedData.payload.comments,
            defectId: requestedData.payload.defectId,
          };
        }
        return order;
      });

      state.dashboardResponseLevel1.data = newReponseData;
    },
  },
});

export const {
  updateOrderTypeData,
  resetResponseLevel1,
  resetResponseLevel2,
  updateSelectedKey,
  updateSelectedKeyTopic,
  updateLevelOnBackClick,
  updatePieOrderType,
  updatePieStats,
  toggleSearchModal,
  resetState,
  resetLevelGrid,
  resetGrid,
  resetSearch,
  setSelectedOption,
  resetSelectOption,
  updateLevel,
  updateRowOrderId,
  updatePageNum,
  createSearchRequest,
  setGlobalRegion,
  setPayloadModalVisibility,
  updateFailedOrdersResponseData,
  setFailedStatusOnly,
} = dashboardslice.actions;
export default dashboardslice.reducer;
